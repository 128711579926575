<template>
    <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <SportsComponent :category="category" :lastPage="counterGoBack"/>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import SportsComponent from "@/components/Shared/Products/Quote/Sports.vue";
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';

export default {
    setup(){
        const router = useRouter();
        const category = ref('');
        let counterGoBack = ref(0);
        let continueQuote = ref(false);

        onBeforeMount(()=>{
            category.value = router.currentRoute.value.params.category;
        });

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
            console.log("goToBack", counterGoBack.value)
        }

        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueQuote
        }
    },
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        SportsComponent
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>