<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirectHistory()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <QuotationCheckbtnTextSelectComponent ref="ComponentsSportsRef" :question="question" :options="chooseButtonsOptions" :view_class_view="view_class_view" :buttons_styles="buttons_styles" :options_list="questionsOptionsList" :activation_value="'Sí'" :validations="rulesValidations" />
                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/sports.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted, onBeforeMount} from 'vue';
import { helpers, requiredIf } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import Quote from '@/classes/Quotation.js';
import { availableFormStorage, getSessionStorage, getLocalStorage, createLocalStorage } from '@/helpers/storage.js';
import DataQuotation from "@/classes/DataQuotation";

export default {
    name: "Sports",
    setup(){
        const catalogSportClass = ref(null);
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const ComponentsSportsRef = ref(null);
        const category = ref("");
        const quoteClass = ref(null);
        const dataQuotationClass = ref(null);

        const questionsOptionsList = ref({
			options: [],
			selected: []
		});
        const validations = ref({});

        const questionTitle = ref("Deportes");
        const question = '¿Practica deportes? '
        const imageCategory = 'personal_info.svg';
        const chooseButtonsOptions = [{ id: 'button_yes', value: 'Sí', text: 'Si' },{ id: 'button_no', value: 'No', text: 'No' }];
        
        let isActive = ref(false);

        const buttons_styles = {
            buttonSelected:"",
            activeColorBackground: '#feb72b !important',
            inactiveColorBackground: '#FFFFFF !important',
            activeColorText: '#FFFFFF !important',
            inactiveColorText: '#feb72b !important',
            activeBorderColor: '#feb72b',
            inactiveBorderColor: '#feb72b'
        };

        onMounted(async()=>{
            quoteClass.value = new Quote();
            category.value = getCategory();
            dataQuotationClass.value = new DataQuotation();
            await createDataQuotation();

            isLoading.value = false;
            isActive.value = true;
            
            const quote = quoteClass.value.get(category.value);
            if(quote.is_additional_insured == "Para Adicional")
            {
                configurationForAditional();
            }

            loadFormStorage();
        });

        const createDataQuotation = async () => {
            const userInformation = getSessionStorage('user-information');
            let quoteJson = getQuoteFromStorage();
            
            if(quoteJson){
                quoteJson.person.client_id = userInformation.user_data.client_id;
                quoteJson = {
                    ...quoteJson.person,
                    ...quoteJson
                }
            }

            dataQuotationClass.value = dataQuotationClass.value.new(getCategory(), quoteJson);
            questionsOptionsList.value.options = await dataQuotationClass.value.get_activities_list();
        }

        const getQuoteFromStorage = () => {
            const product = getCategory(false);
            return getLocalStorage(`quote-${product}`);
        }

        const configurationForAditional = () => {
            setTitle();
        }

        const loadFormStorage = () => {
            try{
                
                if(!availableFormStorage('quote-sports')) return;
                
                const data = getLocalStorage('quote-sports');
                const chooseButtonSelected = data.options_selected == 'Sí'? 'yes':'no';
                const input = document.getElementById(`button_${chooseButtonSelected}`);
                input.click();

                ComponentsSportsRef.value.OptionsSelectorRef.options_list.selected = data.selection_list;
            }catch(error){
                console.log("error:", error)
            }
        }

        const setTitle = () => {
            questionTitle.value += " para adicional";
        }

        const getCategory = () => {
            return route.params.product;
        }

        const saveData = (values, category) => {
            const nameStorage = `quote-${category}`;
            
            let quote = getLocalStorage(nameStorage);
            quote.sports = values.selection_list;
            createLocalStorage(nameStorage, quote)
        }

        let view_class_view = {
            div_container_error:{
                container:"my-2 w-full",
                text_error:"text-red-600",
            }
        };
        
        let rulesValidations = {
            options_selected: {
                required: helpers.withMessage("El campo es requerido", requiredIf(function(){
                    return true;
                }))
            },
            selection_list: {
                required: helpers.withMessage("El campo es requerido", requiredIf(function(value, e){
                    return e.options_selected == 'Sí';
                }))
            }
        };
            
        const next = async ()   => {
            const ComponentsSportsRefs = ComponentsSportsRef.value.execute_validation();
            if(!ComponentsSportsRefs) return false ;
            createLocalStorage('quote-sports', ComponentsSportsRefs);
            saveData(ComponentsSportsRefs, category.value);
            router.push('ocupacion')
        }

        const redirectHistory = () => router.go(-1);
        
        return {
            next,
            question,
            isActive,
            isLoading,
            validations,
            questionTitle,
            questionsOptionsList,
            imageCategory,
            rulesValidations,
            view_class_view,
            chooseButtonsOptions,
            buttons_styles,
            ComponentsSportsRef,
            redirectHistory
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>